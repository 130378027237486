import getAddresses from 'api/analytics/get-addresses'
import { useContext, useEffect, useState } from 'react'
import StoreAddress from 'types/store-address'
import styles from './AddressesFilter.module.css'
import Button from 'UI/Button'
import filterStyles from "../DropDown/DropDown.module.css";
import { FilterContext } from '../DropDown/DropDown'

type Props = {
  addresses:StoreAddress[] | null
  selected:StoreAddress['storeId'][]
  setSelected:(list:StoreAddress['storeId'][]) => void
}

const AddressesFilter = ({addresses,selected,setSelected}: Props) => {
  const [list, setlist] = useState(selected)
  const {close} = useContext(FilterContext)
  const handleClick = (id:StoreAddress['storeId']) => {
    setlist(prev => {
      if(prev.includes(id)) {
        return prev.filter(sID => sID !== id)
      } else return [...prev,id]
    })
  }

  return (
    <div className={styles.body}>
      <ul className={styles.list}>
      <li
            className={`${styles.all} ${!list.length && styles.selected}`}
            onClick={() => setlist([])}
            >
            <i/>
              <span>Все адреса</span>
          </li>
      {addresses?.map((({address,storeId}) => {
        const isSelected = list.includes(storeId)
        return (
          <li
            key={storeId}
            className={isSelected ? styles.selected : ''}
            onClick={() => handleClick(storeId)}
            >
            <i/>
            <div>
              <p>{address}</p>
              {/* <span>г. Москва (Добавить город отдельно)</span> */}
              <span></span>
            </div>
          </li>
        )
      }))}
      </ul>
      <div className={styles.actionContainer}>
        <Button
          className={filterStyles.buttonReset}
          onClick={() => setlist([])}
        >
          Сбросить
        </Button>
        <Button
          className={filterStyles.buttonConfirm}
          onClick={() => {
            setSelected(list)
            close()
          }}
        >
          Применить
        </Button>
      </div>
    </div>
  )
}

export default AddressesFilter