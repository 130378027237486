import React, { useState } from "react";
import Switch from "UI/Switch";
import styles from "../Pickup.module.css";
import DropDown from "pages/Main/pages/Analytics/components/DropDown/DropDown";
import PickupSelectFormat, { PickupFormatOptionName } from "./PickupSelectFormat";
import PickupInput from "./PickupInput";
import Icon from "UI/Icon";
import useHint from "hooks/use-hint";
import PickupSettings, { PickupSettingsWithStoreData } from "types/pickup-settings";

const StorageHintText = 'Укажите, сколько времени будет храниться заказ \nпосле приготовления. Это время будет \nотображаться в приложении для покупателей.'
const intervalHintText = 'Перерыв между интервалами'
const limitHintText = 'Максимальное число заказов на одно \nвремя. Если лимит будет достигнут,\n выбранное время для заказа станет недоступным.'
type Data = PickupSettingsWithStoreData | PickupSettings

type Props = {
  data:Data
  editSettings:(data:Data) => void
}

const PickupTableRow = ({data,editSettings}: Props) => {
  // const [switchState, setSwitchState] = useState(false);
  const StorageHint = useHint(StorageHintText)
  // StorageHint.show()
  const IntervalHint = useHint(intervalHintText)
  const LimitHint = useHint(limitHintText)
  const isStore = 'storeId' in data
  return (
    <tr>
      <td style={{width: '180px'}}>
        {!isStore && <div className={styles.tableRowAddress}><p>Все локации</p></div>}
        {isStore && (
          <div className={styles.tableRowAddress}>
          <p>
            {data?.street ? `ул. ${data?.street}` : ''}
            {data?.houseNumber ? `, д. ${data?.houseNumber}` : ''}
            {data?.building ? `, корп. ${data?.building}` : ''}
          </p>
          <span>г. {data?.city}</span>
          </div>
        )}
      </td>
      <td style={{width: '120px'}}>
        <p>
          <Switch setValue={(v) => editSettings({...data,pickupAvailable:v})} value={data.pickupAvailable} locked/>
        </p>
      </td>
      <td>
        {data.pickupAvailable && <div className={styles.tableRowParamsContainer}>
          <div className={styles.tableRowParamsBlock}>
            <span className={styles.settingsTableSubTitle}>Формат</span>
            <DropDown
              filterComponent={
                <PickupSelectFormat setFastPickup={(v) => editSettings({...data,fastPickup:v})} value={data.fastPickup}/>
              }
            >
              <span className={styles.formatSelectedText}>{PickupFormatOptionName(data.fastPickup)}</span>
            </DropDown>
          </div>

          {data.fastPickup && <div className={styles.tableRowParamsBlock}>
            <span className={styles.settingsTableSubTitle}>
              Время приготовления
            </span>
            <PickupInput
              label="минут"
              initialValue={String(data.avgCookingTime)}
              handleChangeValue={(value) => editSettings({...data,avgCookingTime:Number(value)})}
            />
          </div>}
         
          <div className={styles.tableRowParamsBlock}>
            <span 
            onPointerEnter={StorageHint.show}
            onPointerLeave={StorageHint.hide}
            className={styles.settingsTableSubTitle}>
              Хранение
              <span
              className={styles.tableRowParamsHint}
              >
                {StorageHint.hint}
                <Icon icon="info" />
              </span>
            </span>
            <PickupInput
              label="минут"
              initialValue={String(data.storageTime)}
              handleChangeValue={(value) => editSettings({...data,storageTime:Number(value)})}
            />
          </div>

          {!data.fastPickup && <div className={styles.tableRowParamsBlock}>
            <span
            onPointerEnter={IntervalHint.show}
            onPointerLeave={IntervalHint.hide}
            className={styles.settingsTableSubTitle}>
              Интервал
              <span
              className={styles.tableRowParamsHint}
              
              >
                {IntervalHint.hint}
                <Icon icon="info" />
              </span>
            </span>
            <PickupInput
              label="минут"
              initialValue={String(data.timeInterval)}
              handleChangeValue={(value) => editSettings({...data,timeInterval:Number(value)})}
            />
          </div>}

          {!data.fastPickup && <div className={styles.tableRowParamsBlock}>
            <span
            onPointerEnter={LimitHint.show}
            onPointerLeave={LimitHint.hide}
            className={styles.settingsTableSubTitle}>
            Лимит заказов
              <span
              className={styles.tableRowParamsHint}
              >
                {LimitHint.hint}
                <Icon icon="info" />
              </span>
            </span>
            <PickupInput
              initialValue={String(data.maxOrdersPerTime)}
              handleChangeValue={(value) => editSettings({...data,maxOrdersPerTime:Number(value)})}
            />
          </div>}
          {data.fastPickup && (
            <>
            <div className={styles.tableRowParamsBlock}/>
            <div className={styles.tableRowParamsBlock}/>
            </>
          )}
        </div>}
        {!data.pickupAvailable && <div className={styles.tableRowParamsContainer}>
          <div className={styles.tableRowParamsBlock}/>
          <div className={styles.tableRowParamsBlock}/>
          <div className={styles.tableRowParamsBlock}/>
          <div className={styles.tableRowParamsBlock}/>
        </div>}
      </td>
    </tr>
  );
};

export default PickupTableRow;
