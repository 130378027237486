import React, { useContext, useEffect, useRef, useState } from "react";

import pickupSettingMode from "api/pickup-settings/pickup-setting-mode";
import { toast } from "react-toastify";
import getStorePickupSetting from "api/pickup-settings/get-store-pickup-setting";
import PickupSettings, {
  PickupSettingsWithStoreData,
} from "types/pickup-settings";
import mainPickupSetting from "api/pickup-settings/main-pickup-setting";
import { diff } from "deep-diff";
import saveStorePickupSetting from "api/pickup-settings/save-store-pickup-setting";
import { notification } from "hooks/use-notifications";
import appContext from "utils/app-context";

type SeetingsTypes = boolean | number

export default () => {
  const {isAuthenticated} = useContext(appContext)
  const [generalParamsForAll, setGeneralParamsForAll] = useState(false);
  const [generalSettings, setgeneralSettings] = useState<null | PickupSettings>(
    null
  );
  const [stores, setStores] = useState<null | PickupSettingsWithStoreData[]>(
    null
  );
  const settings = useRef({ generalParamsForAll, generalSettings, stores });
  const [saveAvalaible, setSaveAvalaible] = useState(false);
  const fetchMode = async () => {
    const modeResult = await pickupSettingMode();
    if (!modeResult)
      toast.warning("Не удалось получить часть настроек самовывоза");
    else if ("description" in modeResult)
      toast.warning(
        `Не удалось получить часть настроек самовывоза: ${modeResult.description}`
      );
    else if ("settingsCommonMode" in modeResult) {
      setGeneralParamsForAll(modeResult.settingsCommonMode);
      settings.current.generalParamsForAll = modeResult.settingsCommonMode;
    }
  };

  const fetchStoresSettings = async () => {
    const result = await getStorePickupSetting();
    if (!result)
      toast.warning("Не удалось получить адреса с настройками самовывоза");
    else if ("description" in result)
      toast.warning(
        `Не удалось получить адреса с настройками самовывоза: ${result.description}`
      );
    else {
      setStores(result);
      settings.current.stores = result;
    }
  };

  const fetchGeneralSettings = async () => {
    const result = await mainPickupSetting();
    if (!result) toast.warning("Не удалось общие настройки самовывоза");
    else if ("description" in result)
      toast.warning(
        `Не удалось общие настройки самовывоза: ${result.description}`
      );
    else {
      setgeneralSettings(result);
      settings.current.generalSettings = result;
    }
  };

  useEffect(() => {
    if(!isAuthenticated) return
    fetchMode();
    fetchStoresSettings();
    fetchGeneralSettings();
  }, [isAuthenticated]);

  const checkChanges = () => {
    const isModeWasChanged =
      generalParamsForAll !== settings.current.generalParamsForAll;
    const isGeneralSettingsWasChanged = diff(
      generalSettings,
      settings.current.generalSettings
    );
    const isStoresWasChanged: PickupSettingsWithStoreData["storeId"][] = [];
    if (stores && settings.current.stores) {
      for (const store of stores) {
        const initialStore = settings.current.stores?.find(
          ({ storeId }) => storeId === store.storeId
        );
        if (initialStore) {
          const hasChanges = diff(store, initialStore);
          if (hasChanges) isStoresWasChanged.push(store.storeId);
        }
      }
    }

    return {
      isModeWasChanged,
      isGeneralSettingsWasChanged,
      isStoresWasChanged,
    };
  };

  useEffect(() => {
    // Checking if the settings have changed
    const {
      isGeneralSettingsWasChanged,
      isModeWasChanged,
      isStoresWasChanged,
    } = checkChanges();
    if (
      isModeWasChanged ||
      isGeneralSettingsWasChanged ||
      isStoresWasChanged.length
    ) {
      setSaveAvalaible(true);
    } else {
      setSaveAvalaible(false);
    }
  }, [stores, generalSettings, generalParamsForAll]);

  const handleSave = async () => {
    if (!generalSettings || !stores) return;

    const {
      isGeneralSettingsWasChanged,
      isModeWasChanged,
      isStoresWasChanged,
    } = checkChanges();
    if (isModeWasChanged) {
      const modeResult = await pickupSettingMode(generalParamsForAll);
      // if(!modeResult)
      //   toast.warning('Не удалось изменить часть настроек самовывоза')
      // else if ('description' in modeResult)
      //   toast.warning(`Не удалось изменить часть настроек самовывоза: ${modeResult.description}`)
    }
    if (isGeneralSettingsWasChanged) {
      const result = await mainPickupSetting(generalSettings);
      if (!result)
        toast.warning("Не удалось изменить общие настройки самовывоза");
      else if ("description" in result)
        toast.warning(
          `Не удалось изменить общие настройки самовывоза: ${result.description}`
        );
    }
    if (isStoresWasChanged.length) {
      for (const storeID of isStoresWasChanged) {
        const Store = stores?.find(({ storeId }) => storeId === storeID);
        const StoreFromSettings = settings.current.stores?.find(({ storeId }) => storeId === storeID);
        if (Store) {

          const fixedStore = (Object.keys(Store)).reduce((store:Record<string,any>,key) => {
            const K = (key as keyof typeof Store)
            if(typeof Store[K] === 'number' && Store[K] === 0 && StoreFromSettings) {
              store[K] = StoreFromSettings[K]
            } else store[K] = Store[K]
            return store
          },{})

          const result = await saveStorePickupSetting(storeID, fixedStore as PickupSettings);
          if (!result)
            toast.warning(
              `Не удалось изменить настройки самовывоза для магазина ${storeID}`
            );
          else if ("description" in result)
            toast.warning(
              `Не удалось изменить настройки самовывоза для магазина ${storeID}: ${result.description}`
            );
        }
      }
    }
    notification("Настройки сохранены", "success");
    settings.current = { generalParamsForAll, generalSettings, stores };
    setSaveAvalaible(false);
  };

  return {
    generalParamsForAll,
    setGeneralParamsForAll,
    generalSettings,
    setgeneralSettings,
    saveAvalaible,
    stores,
    setStores,
    handleSave,
  };
};
