import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { ActionButtonDropdown } from "pages/Main/pages/Card&News/pages/Stories/components/StoriesTableRow";
import React, { Dispatch, SetStateAction, useRef } from "react";
import { Product } from "types/tagging";
import TaggingTagSelect from "./TaggingTagSelect";
import styles from "../Tagging.module.css";
import Button from "UI/Button";
import createProduct from "api/tagging/create-product";
import { toast } from "react-toastify";
import { notification } from "hooks/use-notifications";
import { sortTags } from "utils/tag-list";
type Props = {
  newProduct: Product | null;
  setNewProduct: (p: Product | null) => void;
  setTotal: Dispatch<SetStateAction<number>>;
  update: () => void;
};

const TaggingNewProduct = ({ newProduct, setNewProduct, update ,setTotal}: Props) => {
  const handleCreateProduct = async () => {
    if (!newProduct) return null;
    const res = await createProduct(newProduct);
    if (!res) notification("Не удалось создать новый товар", "warning");
    else if ("description" in res)
      if (res.code === 8) {
        notification(
          `Не удалось создать новый товар: У вас уже есть товар с таким названием`,
          "warning"
        );
      } else
        notification(
          `Не удалось создать новый товар: ${res.description}`,
          "warning"
        );
    else {
      setNewProduct(null);
      notification("Товар создан", "success");
      setTotal(prev => prev+1)
      update();
    }
  };
  const isEmpty = newProduct?.productName.trim().length === 0;

  return newProduct ? (
    <motion.tr
      layout
      initial={{ height: 0 }}
      animate={{ height: "auto" }}
      exit={{ height: 0 }}
      transition={{ duration: 0.2, ease: "circInOut" }}
      className={styles.productRow}
    >
      <td className={styles.productRowProductName}>
        <div className={styles.productRowNewNameContainer}>
          <input
            autoFocus
            onSubmit={handleCreateProduct}
            type="text"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                const btn = document.querySelector(
                  "#createProductButton"
                ) as HTMLElement;
                btn?.click();
              }
            }}
            value={newProduct?.productName}
            placeholder="Введите название товара"
            onChange={(e) =>
              setNewProduct({ ...newProduct, productName: e.target.value })
            }
          />
          <Button
            icon="done"
            id="createProductButton"
            data-white
            handlePromise={handleCreateProduct}
            show-loading
            disabled={isEmpty}
          ></Button>
        </div>
      </td>
      <td>
        <TaggingTagSelect
          p={newProduct}
          setTags={(tags) => {
           sortTags(tags)
            setNewProduct({ ...newProduct, tags })
          }}
        />
      </td>
      <td className={styles.productRowProductName}></td>
      <td>
        <div className={styles.productRowActions}>
          <div></div>
          <ActionButtonDropdown
            icon="delete"
            headerText=""
            description=""
            confirmText=""
            confirmDataAttr="data-red"
            hintText="Отмена"
            onPress={() => setNewProduct(null)}
          />
        </div>
      </td>
    </motion.tr>
  ) : null;
};

export default TaggingNewProduct;
