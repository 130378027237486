import React, { useContext, useEffect, useState } from "react";
import styles from "../TransactionsInfoBlocks.module.css";
import formatNum from "utils/format-num";
import InfoBlock from "./InfoBlock";
import { toast } from "react-toastify";
import { TransactionsContext } from "../../../Transactions";
import getPaymentMethodStats from "api/analytics/get-payment-method-stats";
import StatsPaymentMethods from "types/stats-payment-methods";
type Props = {};

const InfoBlockPayMethods = (props: Props) => {
  const [stats, setStats] = useState<StatsPaymentMethods | null>(null)
  console.log("🚀 ~ InfoBlockPayMethods ~ stats:", stats)
  const {filters} = useContext(TransactionsContext)
  useEffect(() => {
    setStats(null)
    getPaymentMethodStats(filters).then(res => {
      if (!res) toast.error("Не удалось получить статистику по способам оплаты");
      else if ("description" in res) toast.error(`Ошибка при получении статистики по способам оплаты:\n${res.description}`);
      else setStats(res);
    })
  }, [filters])
  return (
    <div className={styles.blocksWrapper} data-loading={!stats}>
      <InfoBlock>
        <div className="flex">
          <h3 className={`${styles.blockTitle}`}>
            Другое
          </h3>
          <i className={styles.blockHint}>
            <span className={styles.hintBody}>
              Оплата пластиковой картой, наличными <br />
              или любыми другими способами.
            </span>
          </i>
        </div>
        <p className={styles.blockValue}>
          {formatNum(stats?.alternativeRevenue)} <span className={styles.blockCurrency}>₽</span>
        </p>
        <p className={styles.blockDesc}>{formatNum(stats?.alternativeCount)} транзакций</p>
      </InfoBlock>

      <InfoBlock>
        <h3 className={styles.blockTitle}>СБП</h3>
        <p className={styles.blockValue}>
          {formatNum(stats?.sbpRevenue)} <span className={styles.blockCurrency}>₽</span>
        </p>
        <p className={styles.blockDesc}>{formatNum(stats?.sbpCount)} транзакций</p>
      </InfoBlock>

      <InfoBlock>
        <h3 className={styles.blockTitle}>Привязанная карта</h3>
        <p className={styles.blockValue}>
          {formatNum(stats?.cloudPaymentsRevenue)} <span className={styles.blockCurrency}>₽</span>
        </p>
        <p className={styles.blockDesc}>{formatNum(stats?.cloudPaymentsCount)} транзакций</p>
      </InfoBlock>
    </div>
  );
};

export default InfoBlockPayMethods;
