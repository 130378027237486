import getArchiveStories from "api/stories/get-archive-stories";
import getStories from "api/stories/get-stories";
import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Story from "types/story";
import appContext from "utils/app-context";

export default () => {
  const {isAuthenticated} = useContext(appContext)
  const [stories, setStories] = useState<Story[] | null>(null);
  const [archive, setArchive] = useState<Story[] | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const loadStories = useCallback(async () => {
    try {
      const [currentStories, archivedStories] = await Promise.all([
        getStories(0, 50),
        getArchiveStories(0, 50),
      ]);

      if (!currentStories || "description" in currentStories) {
        throw new Error(
          `Ошибка при получении новостей: ${
            currentStories?.description || "Неизвестная ошибка"
          }`
        );
      }
      const items = currentStories.items.sort(
        (A, B) => B.displayOrderIndex - A.displayOrderIndex
      );
      setStories(items);
      if (!archivedStories || "description" in archivedStories) {
        throw new Error(
          `Ошибка при получении архива: ${
            archivedStories?.description || "Неизвестная ошибка"
          }`
        );
      }
      setArchive(archivedStories.items);
    } catch (error) {
      //   toast.error((error as Error).message);
      setError(error as Error);
    }
  }, []);


  useEffect(() => {
    if(isAuthenticated) loadStories();
  }, [isAuthenticated]);

  return {stories,archive,error,setError,loadStories,setStories,setArchive}
};
