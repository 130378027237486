import React, { useContext, useEffect } from 'react'
import Icon from 'UI/Icon'
import PageWrapper from 'UI/PageWrapper'
import styles from './Loading.module.css'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import appContext from 'utils/app-context'
const Loading = () => {
  return (
    <PageWrapper className={styles.loadingPage}>
        <motion.div
        className={styles.loaderWrapper}
        animate={{rotate:[0,360]}}
        transition={{repeat:Infinity,ease:'linear',duration:0.5}}
        >
          <Icon icon='loading'/>
        </motion.div>
    </PageWrapper>
  )
}

export default Loading