import React, { lazy, useEffect, useState } from "react";
import styles from "./Updates.module.css";
import img from "assets/images/updates-banner-placeholder.svg";

type UpdateItem = {
  title: string;
  date: string;
  content: string;
};

const Updates = () => {
  const [items, setItems] = useState<UpdateItem[]>([]);
  useEffect(() => {
    import("./updates-list.ts").then((module) => {
      setItems(module.default);
    });
  }, []);
  return (
    <div className={styles.page}>
      <h2 className={styles.title}>Обновления</h2>
      <div className={styles.pageDescContainer}>
        <img src={img} alt="" />
        <p>
          Добро пожаловать в новую маркетинговую платформу SWiP Office! В этом
          разделе мы будем сообщать вам о новостях и изменениях, которые
          происходят в нашем продукте и компании. Если у вас возникнут вопросы
          или необходимость в помощи, просто дайте нам знать. Наша команда с
          радостью поможет и проконсультирует вас. Мы ценим наше сотрудничество
          с вами и благодарим за выбор SWiP!
        </p>
      </div>
      <ul className={styles.updatesList}>
        {items.map(({ content, date, title }) => (
          <li className={styles.updatesListRow}>
            <div className={styles.updatesListRowTitle}>
              <h3>{title}</h3>
              <p>{date}</p>
            </div>
            <div
              className={styles.updatesListRowContent}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Updates;
