import {
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import routes from "./routes";
import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import AppContext from "utils/app-context";
import { useAppData } from "hooks/use-app-data";
import { Slide, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from "UI/Modal";
import useNotifications, { notification } from "hooks/use-notifications";
import Loading from "pages/Loading/Loading";
function App() {
  const navigation = useNavigate();
  const { AppContextValue } = useAppData();
  let element = useRoutes(routes.map(({ route }) => route));
  const location = useLocation();
  const rootPath = "/" + location.pathname.split("/")[1];
  const {NotificationContainer} = useNotifications()
  
  if (!element) return null;
  return (
    <AppContext.Provider value={AppContextValue}>
      <AnimatePresence mode="sync">
        {React.cloneElement(element, { key: rootPath })}
      </AnimatePresence>
      <Modal/>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        stacked
        theme="light"
        transition={Slide}
        />
        {NotificationContainer}
    </AppContext.Provider>
  );
}

export default App;
