
export interface Product {
  id: number;
  productName: string;
  updatedAt: number;
  tags: Tag[];
}

export interface Tag {
  text: string;
  color: string;
}

export enum SortOptions {
    UPDATED_AT = 'UPDATED_AT',
    PRODUCT_NAME = 'PRODUCT_NAME',
    TAGS_COUNT = 'TAGS_COUNT',
 }

