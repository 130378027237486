import getAddresses from "api/analytics/get-addresses"
import { useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import StoreAddress from "types/store-address"
import appContext from "utils/app-context"
import { notification } from "./use-notifications"

export default () => {
  const {isAuthenticated} = useContext(appContext)
  const [adresses, setadresses] = useState<StoreAddress[] | null>(null)
  const [loading, setloading] = useState(true)
  const updateAdresses = async () => {
    setloading(true)
    const res = await getAddresses()
    
    if (!res) notification('Не удалось получить адреса','warning')
    else if('description' in res) notification(`Ошибка при получении адресов:\n${res.description}`,'warning')
    else setadresses(res)
  
    setloading(false)
  }
  useEffect(() => {
    if(isAuthenticated) updateAdresses()
  }, [isAuthenticated])
  return {loading,adresses}
}