import React, { useContext, useEffect, useState } from "react";
import styles from "../TransactionsInfoBlocks.module.css";
import formatNum from "utils/format-num";
import InfoBlock from "./InfoBlock";
import StatsOrderTypes from "types/stats-order-types";
import getPaymentMethodStats from "api/analytics/get-payment-method-stats";
import { toast } from "react-toastify";
import { TransactionsContext } from "../../../Transactions";
import getOrderTypeStats from "api/analytics/get-order-type-stats";
import { MainContext } from "pages/Main/Main";
type Props = {};

const InfoBlockOrderTypes = (props: Props) => {
  const [stats, setStats] = useState<StatsOrderTypes | null>(null)
  const {filters} = useContext(TransactionsContext)
  const {LicensesService:{licenses}} = useContext(MainContext)
  useEffect(() => {
    setStats(null)
    getOrderTypeStats(filters).then(res => {
      if (!res) toast.error("Не удалось получить статистику по типам заказа");
      else if ("description" in res) toast.error(`Ошибка при получении статистики по типам заказа:\n${res.description}`);
      else setStats(res);
    })
  }, [filters])
  return (
    <div className={styles.blocksWrapper} data-loading={!stats}>
      <InfoBlock>
          <h3 className={`${styles.blockTitle}`}>
          На кассе
          </h3>
        <p className={styles.blockValue}>
          {formatNum(stats?.qrRevenue)} <span className={styles.blockCurrency}>₽</span>
        </p>
        <p className={styles.blockDesc}>{formatNum(stats?.qrCount)} транзакций</p>
      </InfoBlock>

      <InfoBlock>
        <h3 className={styles.blockTitle}>За столом</h3>
        <p className={styles.blockValue}>
          {formatNum(stats?.precheckRevenue)} <span className={styles.blockCurrency}>₽</span>
        </p>
        <p className={styles.blockDesc}>{formatNum(stats?.precheckCount)} транзакций</p>
      </InfoBlock>

      {licenses?.delivery && <InfoBlock>
        <h3 className={styles.blockTitle}>Доставка</h3>
        <p className={styles.blockValue}>
          {formatNum(stats?.deliveryRevenue)} <span className={styles.blockCurrency}>₽</span>
        </p>
        <p className={styles.blockDesc}>{formatNum(stats?.deliveryCount)} транзакций</p>
      </InfoBlock>}
      {licenses?.pickup && <InfoBlock>
        <h3 className={styles.blockTitle}>Самовывоз</h3>
        <p className={styles.blockValue}>
          {formatNum(stats?.preorderRevenue)} <span className={styles.blockCurrency}>₽</span>
        </p>
        <p className={styles.blockDesc}>{formatNum(stats?.preorderCount)} транзакций</p>
      </InfoBlock>}
    </div>
  );
};

export default InfoBlockOrderTypes;
