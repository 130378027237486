import { Tags } from "types/orders-responce";

export default [
    {
        title:'AI',
        value:Tags.AI_DISCOUNT,
        desc:'Чек покупателя, при котором была применена персональная акция от Искусственного интеллекта'
    },
    {
        title:'Реферал',
        value:Tags.REFERRAL,
        desc:'Покупатель, который пришел по приглашению реферера'
    },
    {
        title:'Реферер',
        value:Tags.REFERRER,
        desc:'Покупатель, который приглашает своих друзей в ваше заведение через приложение'
    },
    {
        title:'Оценка',
        value:Tags.RATING
    },
    {
        title:'Без тегов',
        value:Tags.EMPTY
    },
]