import { table } from "console";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import Story from "types/story";
import styles from "../Stories.module.css";
import { AnimatePresence, Reorder, useDragControls } from "framer-motion";
import NewsTableRow from "./StoriesTableRow";
import reorderStory from "api/stories/reorder-story";
import appContext from "utils/app-context";
type Props = {
  stories: Story[];
  setStories: Dispatch<SetStateAction<Story[] | null>>;
  updateList: () => Promise<any>;
  isArchive?:true
};

const StoriesTable = ({ stories, setStories, updateList,isArchive }: Props) => {
  const [dragableItemID, setdrageableItem] = useState<Story['id'] | null>(null)
  const handleReorder = () => {
    setdrageableItem(null)
    if(typeof dragableItemID === 'object') return

    const sorted = [...stories].sort((A,B) => B.displayOrderIndex - A.displayOrderIndex)

    const currentRealIndex = stories.findIndex(({id}) => id === dragableItemID)

    if(currentRealIndex === -1) return

    const newDisplayIndex = sorted[currentRealIndex].displayOrderIndex
    
    reorderStory({
      newDisplayOrderIdx:newDisplayIndex,
      storyId:dragableItemID
    })

  }
  return (
    <div className={styles.newsTableWrapper} onPointerUp={handleReorder}>
      <ul className={styles.newTableHeader}>
        <li>{isArchive ? 'Дата архивации' : 'Дата запуска'}</li>
        <li>Название</li>
        <li>Статус</li>
        <li>Действия</li>
        <li></li>
      </ul>
      <Reorder.Group
        axis="y"
        className={styles.newsTableBody}
        onReorder={setStories}
        values={stories}
        variants={variants}
        initial="closed"
        animate="open"
      >
        <AnimatePresence>
          {stories.map((story) => (
            <NewsTableRow
              story={story}
              key={story.id}
              updateList={updateList}
              setDragableStory={setdrageableItem}
              isdragable={dragableItemID}
            />
          ))}
        </AnimatePresence>
      </Reorder.Group>
    </div>
  );
};
const variants = {
  open: {
    transition: { staggerChildren: 0.03, delayChildren: 0.05 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};
export default StoriesTable;
