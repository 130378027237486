import React, { useContext, useState } from "react";
import styles from "./ListFilter.module.css";
import Button from "UI/Button";
import filterStyles from "../../Transactions.module.css";
import { FilterContext } from "../../../../components/DropDown/DropDown";
type Item<T = any> = {
  title: string;
  value: T;
  desc?: string;
};

type Props<T = any> = {
  allTypes: string;
  list: Item<T>[] | null;
  selected: T[];
  setSelected: (list: T[]) => void;
};

const ListFilter = ({ allTypes, list, selected, setSelected }: Props) => {
  const [localList, setLocalList] = useState(selected);
  const { close } = useContext(FilterContext);

  const handleClick = (id: Item["value"]) => {
    setLocalList((prev) => {
      if (prev.includes(id)) {
        return prev.filter((sID) => sID !== id);
      } else return [...prev, id];
    });
  };

  return (
    <div className={styles.body}>
      <ul className={styles.list}>
        <li
          className={`${styles.all} ${!localList.length && styles.selected}`}
          onClick={() => setLocalList([])}
        >
          <i />
          <span>{allTypes}</span>
        </li>
        {list?.map(({ title, value ,desc}, i) => {
          const isSelected = localList.includes(value);
          return (
            <li
              key={i}
              className={isSelected ? styles.selected : ""}
              onClick={() => handleClick(value)}
            >
              <i />
              <div>
                <p>{title}</p>
                {desc && <span>{desc}</span>}
              </div>
            </li>
          );
        })}
      </ul>
      <div className={styles.actionContainer}>
        <Button
          className={filterStyles.buttonReset}
          onClick={() => setLocalList([])}
        >
          Сбросить
        </Button>
        <Button
          className={filterStyles.buttonConfirm}
          onClick={() => {
            setSelected(localList);
            close();
          }}
        >
          Применить
        </Button>
      </div>
    </div>
  );
};

export default ListFilter;
