import React, { ReactNode, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Order, OrderType, Tags } from "types/orders-responce";
import styles from "../TransactionsTable.module.css";
import Icon, { IconType } from "UI/Icon";
import dayjs from "dayjs";
import orderFilterTypes from "utils/order-filter-types";
import paymentMethodsFilterTypes from "utils/payment-methods-filter-types";
import AiDiscountInfo from "types/ai-discount-info";
import getAiDiscount from "api/analytics/get-ai-discount";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import useHint from "hooks/use-hint";

type Props = {
  order: Order;
};

const TransactionRow = ({
  order: {
    id,
    paidAtLocal,
    phone,
    discountRub,
    awardPoints,
    withdrawPoints,
    tags,
    rating,
    orderType,
    total,
    paymentMethod,
    customerId,
    newCustomer,
    discountPercent,
    totalOriginal,
    orderNumber
  },
}: Props) => {
  let [_, setSearchParams] = useSearchParams();
  const date = dayjs(paidAtLocal);
  
  let points = "";
  if (awardPoints) points = `+${awardPoints}`;
  if (withdrawPoints)
    points +=
      points.length > 0 ? ` / -${withdrawPoints}` : `-${withdrawPoints}`;

  const paymentMethodDisplay = paymentMethodsFilterTypes.find(
    ({ value }) => value === paymentMethod
  )?.title;

  let orderTypeDisplay:ReactNode = orderFilterTypes.find(
    ({ value }) => value === orderType
  )?.title;

  switch (orderType) {
    case OrderType.DELIVERY:
      orderTypeDisplay = <span> <Icon icon="orderType_DELIVERY"/> Заказ {orderNumber}</span>
      break;
  
    case OrderType.PRE_ORDER:
      orderTypeDisplay = <span> <Icon icon="orderType_PRE_ORDER"/> Заказ {orderNumber}</span>
      break;
  }

  return (
    <tr onClick={() => setSearchParams({ id: id })}>
      <td>
        <p>{date.format("DD.MM.YY")}</p>
        <span>{date.format("HH:mm")}</span>
      </td>
      <td>
        <div className={styles.tagList}>
          <OrderTags
            rating={rating}
            tags={tags}
            customerId={customerId}
            orderId={id}
          />
          {/* <span className={`${styles.tag} ${styles.tag__green}`}>AI</span>
                     <span className={`${styles.tag} ${styles.tag__orange}`}>Реферал</span> */}
        </div>
      </td>
      <td>
        <p>
        {phone ? (
          phone
        ) : (
            <>{customerId ? collapseString(customerId) : '-'} <Icon icon="phoneIsEmpty" /></>
        )}
        {newCustomer && <Icon icon="newCustomer" />}
          </p>
      </td>
      <td>
        <p>{paymentMethodDisplay}</p>
        <span>{orderTypeDisplay}</span>
      </td>
      <td>
        <p>{points || "-"}</p>
      </td>
      <td>
        <p>{discountRub ? `${discountRub} ₽` : "-"}</p>
        <span>{discountPercent ? `${discountPercent}%` : "-"}</span>
      </td>
      <td className={styles.cellPrices}>
          <p>{total} ₽</p>
          {totalOriginal > total && <span style={{textDecoration:""}}>{totalOriginal} ₽</span>}
      </td>
      <td></td>
    </tr>
  );
};

function collapseString(str: string, maxLength: number = 5): string {
  if (str.length <= maxLength * 2) {
    return str; // Если строка короткая, возвращаем её как есть
  }

  const start = str.slice(0, maxLength); // Первые 5 символов
  const end = str.slice(-maxLength); // Последние 5 символов
  return `${start}...${end}`;
}

type OrderTagsProps = {
  tags: Order["tags"];
  rating: Order["rating"];
  orderId: Order["id"];
  customerId: Order["customerId"];
  bottomAIHint?: boolean;
};

export const OrderTags = ({
  tags,
  rating,
  customerId,
  orderId,
  bottomAIHint,
}: OrderTagsProps) => {
  const [aiDiscountInfo, setaiDiscountInfo] = useState<null | AiDiscountInfo>(
    null
  );
  const [opened, setopened] = useState(false);
  // customerId = '26423cd8-ccb0-417b-95af-99402c0e192a'
  // orderId = '00003637-0650-4472-a9cc-39b865e79294'
  const updateAiDiscountInfo = async () => {
    const res = await getAiDiscount(orderId, customerId);
    if (!res) toast.error("Не удалось получить информацию об AI скидке");
    else if ("description" in res)
      toast.error(
        `Ошибка при получении информации об AI скидке:\n${res.description}`
      );
    else setaiDiscountInfo(res);
  };

  const RefererHint = useHint('Покупатель, который приглашает своих <br/> друзей в ваше заведение через приложение')
  const ReferalHint = useHint('Покупатель, который пришел по приглашению реферера')

  useEffect(() => {
    if (opened) updateAiDiscountInfo();
  }, [opened]);

  if (!tags || !tags.length) return <p>-</p>;

  return (
    <>
      {tags?.map((tag) => {
        switch (tag) {
          case Tags.REFERRER:
            return (
              <span key={tag} className={`${styles.tag} ${styles.tag__purple}`}
              onPointerEnter={RefererHint.show} 
              onPointerLeave={RefererHint.hide}
              >
                {RefererHint.hint}
                Реферер
              </span>
            );
          case Tags.REFERRAL:
            return (
              <span key={tag} className={`${styles.tag} ${styles.tag__orange}`}
              onPointerEnter={ReferalHint.show} 
              onPointerLeave={ReferalHint.hide}
              >
                {ReferalHint.hint}
                Реферал
              </span>
            );

          case Tags.RATING:
            return (
              <span key={tag} className={`${styles.tag} ${""}`}>
                <Icon icon="tagStar" />
                {rating}
              </span>
            );

          case Tags.AI_DISCOUNT:
            const stages =
              aiDiscountInfo?.aiCampaignUsingProgress.discountSeriesSize;
            const current =
              aiDiscountInfo?.aiCampaignUsingProgress.usedDiscount;
            const date = dayjs(aiDiscountInfo?.dateProgramFinish).format(
              "DD.MM.YY"
            );
            return (
              <span
                key={tag}
                className={`${styles.tag} ${styles.tag__green}`}
                onMouseEnter={() => {
                  if (!opened) setopened(true);
                }}
              >
                <div
                  className={styles.tagAIHintWrapper}
                  data-loading={!aiDiscountInfo}
                  data-bottom-position={bottomAIHint}
                >
                  <div className={styles.tagAIHintHeader}>
                    <Icon icon="heart" />
                    <h3>{aiDiscountInfo?.title}</h3>
                  </div>
                  <p className={styles.tagAIHintDesc}>
                    {aiDiscountInfo?.message}
                  </p>
                  <div className={styles.tagAIHintFooter}>
                    <div>
                      <span>Использовано AI-скидок</span>
                      {stages && (
                        <ul className={styles.tagAIHintCounter}>
                          {Array.from({ length: stages }).map((_, i) => (
                            <li
                              className={
                                i + 1 <= (current || 0) ? styles.current : ""
                              }
                            />
                          ))}
                        </ul>
                      )}
                    </div>
                    <div>
                      <span>Дата окончания</span> <p>{date}</p>{" "}
                    </div>
                  </div>
                  <span className={styles.tagAIHintCategory}>
                    AI: Удержание
                  </span>
                </div>
                AI <Icon icon="greenInfo" />
              </span>
            );
        }
      })}
    </>
  );
};

export default TransactionRow;
