import { Tag } from "types/tagging";

export const TagList:Tag[] = [
    {
      text:'%',
      color:'#43A047'
    },
    {
      text:'хит',
      color:'#FF9F0A'
    },
    {
      text:'веган',
      color:'#009688'
    },
    {
      text:'остро',
      color:'#FF453A'
    },
    {
      text:'сладко',
      color:'#FF9F0A'
    },
    {
      text:'скидка',
      color:'#BF5AF2'
    },
    {
      text:'выгодно',
      color:'#FF2D55'
    },
    {
      text:'Новинка',
      color:'#06B76E'
    },
    {
      text:'без мяса',
      color:'#6AB011'
    },
    {
      text:'сезонное',
      color:'#F56311'
    },
    {
      text:'без сахара',
      color:'#3EBCCB'
    },
    {
      text:'без глютена',
      color:'#8D6E63'
    },
    {
      text:'много белка',
      color:'#FF8660'
    },
    {
      text:'рекомендуем',
      color:'#DD4F81'
    },
    {
      text:'мало калорий',
      color:'#9159D0'
    },
    {
      text:'мало углеводов',
      color:'#6889FF'
    },
    
   ]

export const sortTags = (tags:Tag[]) => {
    tags.sort((A,B) => {
        const indexA = TagList.indexOf(A)
        const indexB = TagList.indexOf(B)
        return indexA - indexB 
    })
} 