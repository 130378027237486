import axios from "axios";
import { TimeRange } from "pages/Main/pages/Analytics/pages/Transactions/Transactions";
import ErrorResponce from "types/error-response";
import OrdersFilters from "types/orders-filters";
import OrdersResponse from "types/orders-responce";
import appendFiltersToUrl from "utils/append-filters-to-url";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
dayjs.extend(utc);

export default async (
  filters: OrdersFilters,
  page: number,
  signal: AbortSignal,
  time?:TimeRange
): Promise<OrdersResponse | null | ErrorResponce | 'canceled'> => {
  const token = localStorage.getItem("token");
  if (!token) return null;

  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string);
    API_URL.pathname += "analytics/orders";
    
    if(time) {
      const [S_hours, S_minutes] = time.start.split(":").map(Number);
      const [E_hours, E_minutes] = time.end.split(":").map(Number);

      filters.range.start = filters.range.start.hour(S_hours).minute(S_minutes).startOf('milliseconds')
      filters.range.end = filters.range.end.hour(E_hours).minute(E_minutes).endOf('milliseconds')
    }

    filters.range.end = filters.range.end.utc()
    filters.range.start = filters.range.start.utc()

    appendFiltersToUrl(API_URL, filters, page);

    const response = await axios.get<OrdersResponse>(API_URL.toString(), {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      signal, // Передаем signal в запрос
    });

    return response.data; // Возвращаем данные
  } catch (error: any) {
    // Если запрос был отменён
    if (error.name === "CanceledError") {
      return 'canceled'
    } else {
      console.error("Request error:", error.message || error);
    }

    return null;
  }
};
